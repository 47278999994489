import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { AppBar, Toolbar, Box, TextField, Button, CircularProgress, IconButton, Menu, MenuItem, Popover, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import logo from './assets/logo.png';
import AccountCircle from '@mui/icons-material/AccountCircle';
import KeycloakService from './keycloakService';

function ChatBot({ token }) {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [isAuthenticated, setIsAuthenticated] = useState(!!token);
  const [anchorEl, setAnchorEl] = useState(null);
  const [agentAnchorEl, setAgentAnchorEl] = useState(null);

  const messagesEndRef = useRef(null);

  const handleInputChange = (e) => setInput(e.target.value);

  const handleSubmit = async () => {
    if (!isAuthenticated) {
      alert('Please log in to chat');
      return;
    }

    if (input.trim() === '') return;
    const newMessage = { role: 'user', content: input };
    const updatedMessages = [...messages, newMessage];
    setMessages(updatedMessages);

    setInput('');
    setLoading(true);

    try {
      await axios.post('https://ask-finance.online/api/chat', {
        messages: updatedMessages
      });

      const eventSource = new EventSource('https://ask-finance.online/api/stream');
      let botMessage = { role: 'assistant', content: '' };

      eventSource.onmessage = (event) => {
        if (event.data === 'END') {
          setLoading(false);
          eventSource.close();
        } else {
          botMessage.content += event.data;
          setMessages((prev) => {
            const newMessages = [...prev];
            newMessages[newMessages.length - 1] = botMessage;
            return newMessages;
          });
        }
      };
    } catch (error) {
      console.error('API call error:', error.response ? error.response.data : error.message);
      setMessages((prev) => [...prev, { role: 'assistant', content: 'Error during API communication' }]);
      setLoading(false);
    }
  };

  const handleClear = () => {
    setMessages([]);
    setInput('');
  };

  const handleLogout = () => {
    KeycloakService.doLogout();
    localStorage.removeItem('token');
    setIsAuthenticated(false);
    window.location.href = 'https://ask-finance.online/';
  };

  const handleMenuClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleAgentClick = (url) => {
    window.open(url, '_blank');
    handleMenuClose();
  };

  const handleAgentHover = (event) => {
    setAgentAnchorEl(event.currentTarget);
  };

  const handleAgentPopoverClose = () => {
    setAgentAnchorEl(null);
  };

  const openAgentPopover = Boolean(agentAnchorEl);

  useEffect(() => {
    if (messagesEndRef.current) {
      messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [messages]);

  const renderMessage = (msg, index) => (
    <Box key={index} sx={{ display: 'flex', justifyContent: msg.role === 'user' ? 'flex-end' : 'flex-start', mb: 1 }}>
      <Box sx={{ maxWidth: '60%', padding: '12px 18px', borderRadius: '15px', backgroundColor: msg.role === 'user' ? '#DCF8C6' : '#F5F5F5', color: '#333', boxShadow: 1 }}>
        <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.content}</ReactMarkdown>
      </Box>
    </Box>
  );

  return (
    <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', padding: '20px', boxSizing: 'border-box', paddingTop: '80px' }}>
      <AppBar position="fixed" sx={{ backgroundColor: 'black' }}>
        <Toolbar sx={{ justifyContent: 'space-between', position: 'relative' }}>
          <img src={logo} alt="Logo" style={{ height: '60px', width: 'auto' }} />
          <Typography variant="h6" sx={{ position: 'absolute', left: '50%', transform: 'translateX(-50%)', color: 'white' }}>
            Finance AI Agent
          </Typography>
          {isAuthenticated && (
            <div>
              <IconButton onClick={handleMenuClick} color="inherit">
                <AccountCircle />
              </IconButton>
              <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
                <MenuItem onClick={() => { window.location.href = 'https://question-platform.com/'; }}>Home</MenuItem>
                <MenuItem onMouseEnter={handleAgentHover} onMouseLeave={handleAgentPopoverClose}>
                  AI Agents
                </MenuItem>
                <MenuItem onClick={handleLogout}>Logout</MenuItem>
              </Menu>
              <Popover
                open={openAgentPopover}
                anchorEl={agentAnchorEl}
                onClose={handleAgentPopoverClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <MenuItem onClick={() => handleAgentClick('https://ask-doctor.online/')}>Ask Medical AI</MenuItem>
                <MenuItem onClick={() => handleAgentClick('https://ask-legal.net/')}>Ask Legal AI</MenuItem>
              </Popover>
            </div>
          )}
        </Toolbar>
      </AppBar>
      <Box sx={{ width: '100%', maxWidth: 800, flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', boxShadow: 3, borderRadius: '12px', overflow: 'hidden', backgroundColor: '#ffffff', mt: 2 }}>
        <Box sx={{ padding: '20px', overflowY: 'auto', flex: 1 }}>
          {messages.map(renderMessage)}
          <div ref={messagesEndRef} />
          {loading && (<Box sx={{ display: 'flex', justifyContent: 'center', mt: 2 }}><CircularProgress /></Box>)}
        </Box>
        <Box sx={{ padding: '10px', borderTop: '1px solid #ddd', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <TextField variant="outlined" fullWidth multiline rows={2} value={input} onChange={handleInputChange} placeholder="Type your message..." sx={{ marginRight: '10px' }} />
          <Button onClick={handleSubmit} variant="contained" sx={{ backgroundColor: 'black', color: 'white', marginRight: '10px', '&:hover': { backgroundColor: '#333' } }}>Send</Button>
          <Button onClick={handleClear} variant="outlined" sx={{ color: 'black', borderColor: 'black', '&:hover': { borderColor: '#333', color: '#333' } }}>Clear</Button>
        </Box>
      </Box>
    </Box>
  );
}

export default ChatBot;

